import { getEnvSpecificUrl } from './helpers/getEnvSpecificUrl';
import logger from './helpers/logger';

const env = process.env.ADSK_AUTH_V2_ENV;
const clientId = process.env.ADSK_AUTH_CLIENT_ID;
const redirectUrl = process.env.ADSK_AUTH_REDIRECT_URL;
const pkceRedirectUrl = process.env.ADSK_PKCE_REDIRECT_URL;
const scope = 'data:read data:write user:read';

// We have to use single instance of pkceClient per page load
// Otherwise we will lose any in memory caching offered by PKCE SDK
let cfpAuthClient = null;

class CFPAuthClient {
  constructor(options) {
    logger.debug('pkceclient: Trying to get window.cfp.providers.auth');
    this.options = options;
    logger.debug('pkceclient: Got the auth handle');
    this.auth = window.cfp.providers.auth;
  }
  register() {
    logger.debug(`pkceclient: invoking register`);
    return this.auth.register(this.options);
  }
  getTokenSilently() {
    return this.auth.getAccessToken();
  }
  loginWithRedirect() {
    return this.auth.login();
  }
  logout(opts) {
    return this.auth.logout(opts);
  }
  isAuthenticated() {
    return this.auth.isLoggedIn();
  }
}

const getPkceAuthClient = async () => {
  logger.info(
    'Attempting to use CFP Auth Provider as Client for Login Purposes'
  );

  if (cfpAuthClient) {
    return cfpAuthClient;
  }

  const hostname = window.location.host;
  const isFedDomain = process.env?.BUILD_FOR === 'federal';

  const options = {
    clientId,
    redirectUri: isFedDomain
      ? getEnvSpecificUrl(redirectUrl)
      : getEnvSpecificUrl(`https://${hostname}/index.html`),
    environment: env,
    redirectUriIframe: isFedDomain
      ? getEnvSpecificUrl(pkceRedirectUrl)
      : getEnvSpecificUrl(`https://${hostname}/pkceredirect.html`),
    scope,
    useRefreshTokens: true,
    cacheLocation: 'sessionstorage',
  };
  logger.info(
    `Initializing CFP Auth Provider with options: ${JSON.stringify(options)}`
  );
  cfpAuthClient = new CFPAuthClient(options);
  await cfpAuthClient.register();
  return cfpAuthClient;
};

export default getPkceAuthClient;
