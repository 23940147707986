import React from 'react';
import { SideNav, getNavData } from '@account-portal/nav';

const NavV2 = ({ flags, loading }) => {
  // This nav should only render static fedramp content
  // As nav is now horizontal on commercial pages and is loaded from
  // https://git.autodesk.com/dpe/mfe-account-navigation

  const navData = getNavData({ isCommercial: false });

  return (
    // TODO: Cleanup SideNav code to remove all commercial references
    <SideNav flags={flags} modules={navData} loading={loading} />
  );
};

export default NavV2;
