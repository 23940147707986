import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { AppErrorBoundary } from '@account-portal/ui-toolkit-react';

import RouteLoader from '../RouteLoader/RouteLoader';

const NonCommercialApp = () => (
  <Switch>
    <Route
      path="/user-access"
      render={props => (
        <AppErrorBoundary key="/user-access">
          <RouteLoader route="/user-access" {...props} />
        </AppErrorBoundary>
      )}
    />

    <Route path="/" render={() => <Redirect to="/user-access" />} />
  </Switch>
);

export default NonCommercialApp;
