import { translate } from '@account-portal/ui-react-i18n';

import { getWindowTop, getWindowSelf } from '../../helpers/window';

const IFrameAlert = ({ t, children }) => {
  if (getWindowSelf() !== getWindowTop()) {
    alert(t('IFRAME_ALERT'));
    window.top.location = window.self.location;
    return null;
  }

  return children;
};

export default translate('security')(IFrameAlert);
