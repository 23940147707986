import jwt from 'jsonwebtoken';
import {
  getAccessTokenFromPkceClient,
  logout,
  terminateSession,
  initializeSession,
} from './helpers/authHelpers';
import logger from './helpers/logger';

export class ClientAuthHandler {
  constructor(axios, isCommercial = true) {
    // This buffer helps refresh the access token 5 seconds (as configured here) earlier
    // before the token expires.
    this.expirationBufferInSeconds = 60;
    this.accessToken = null;
    this.axios = axios;
    this.isCommercial = isCommercial;
  }

  getAccessToken() {
    return this.accessToken;
  }

  setAccessToken(newToken) {
    logger.debug(`ClientAuthHandler: setting new access token...`);
    this.accessToken = newToken;
  }

  async refreshAccessToken() {
    logger.debug('ClientAuthHandler: refreshAccessToken called');
    logger.debug('ClientAuthHandler: calling getAccessTokenFromPkceClient...');

    const { token } = await getAccessTokenFromPkceClient();
    if (this.accessToken !== token) {
      this.setAccessToken(token);
      if (this.isCommercial) {
        logger.info(
          `ClientAuthHandler: Calling initializeSession with new token...`
        );
        initializeSession(this.axios, this.accessToken);
      }
    }
  }

  isTokenValid() {
    logger.debug(`ClientAuthHandler: Checking for token validity...`);
    const token = jwt.decode(this.accessToken);

    if (!token) return false;

    const expiresAt = token.exp * 1000;
    const expiresIn =
      expiresAt - this.expirationBufferInSeconds * 1000 - new Date().getTime();
    const isValid = expiresIn > 0;
    logger.debug(
      `ClientAuthHandler: Token is ${isValid ? 'valid' : 'invalid'}`
    );
    return isValid;
  }

  async signout(returnUrl) {
    logger.debug('ClientAuthHandler: Signout called');
    const retUrl =
      returnUrl || window.location.href || process.env.ADSK_AUTH_REDIRECT_URL;
    logger.debug('ClientAuthHandler: Signout-returnUrl: ', returnUrl);

    // Delete the returnUrl cookie
    document.cookie = `${process.env.ENVIRONMENT}-manage-returnUrl=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; secure; samesite=none`;

    if (this.isCommercial) {
      await terminateSession(this.axios);
      await logout(retUrl);
    } else {
      await logout(process.env.ADSK_AUTH_REDIRECT_URL);
    }
  }
}
