const HomeRedirect = () => {
  // When this request is submitted, the user will be redirected to the home page from Cloudfront.
  // Since we don't have Cloudfront in our local environment, we can skip the redirection when run locally.
  if (!window.self.location.href.includes('local-manage')) {
    const newWindow = window.open('/home', '_self');
    newWindow.opener = null;
  }
  return null;
};

export default HomeRedirect;
