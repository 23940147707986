import React from 'react';
import { BuildContext } from '@account-portal/feynman-core';

const isNonCommercial = () => {
  const { BUILD_FOR } = process.env;
  return BUILD_FOR && BUILD_FOR.toLowerCase() === 'federal';
};

const BuildContextProvider = ({ children }) => {
  return (
    <BuildContext.Provider
      value={{
        isCommercial: !isNonCommercial(),
      }}
    >
      {children}
    </BuildContext.Provider>
  );
};

export default BuildContextProvider;
