const loadApp = async ({ route = null }) => {
  if (!route) return null;

  let App;

  switch (route) {
    case '/user-access': {
      App = await import('@account-portal/app-user-access');
      break;
    }
    default: {
      App = null;
      break;
    }
  }

  return App;
};

export default loadApp;
