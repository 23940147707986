import React, { useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import {
  useBuildContext,
  AuthBoundaryContext,
} from '@account-portal/feynman-core';
import Progress from '@digital-hig/progress';
import ThemeMui from '@digital-hig/theme-mui';

import HigHeader from '../Header/HigHeader/HigHeader';
import HigNav from '../Nav/HigNav';
import styles from './Shell.less';

const HigShell = ({ children, loading }) => {
  const { isCommercial = true } = useBuildContext();
  const { pathname } = window.location;
  const { authBoundary } = useContext(AuthBoundaryContext);
  const [signingIn, setSigningIn] = useState(true);

  useEffect(() => {
    const isUserSignedIn = async () => {
      const token =
        authBoundary &&
        authBoundary.clientAuthHandler &&
        authBoundary.clientAuthHandler.getAccessToken();

      setSigningIn(!isCommercial && !token);
    };

    isUserSignedIn();
  }, []);

  if (signingIn && !isCommercial) {
    return (
      <div className={styles.initialProgress}>
        <ThemeMui themePrefix="accport-progress">
          <Progress label="Loading" size="large" value={1} />
        </ThemeMui>
      </div>
    );
  }

  return (
    <div className={styles.shell}>
      <div className={styles.headerWrapper}>
        <HigHeader loading={loading} />
      </div>
      <div className={classnames(styles.lowerContainer, '_g-sidenav')}>
        <HigNav location={pathname} loading={loading} />
        <div
          id="app-chassis-scroll-container"
          className={classnames(
            styles.contentContainer,
            'adsk-lower-container'
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default HigShell;
