/* istanbul ignore file */
import React, { useContext, useState } from 'react';
import {
  AuthBoundaryContext,
  useBuildContext,
} from '@account-portal/feynman-core';

import NonCommercialHigHeader from './NonCommercialHigHeader';

const HigHeader = () => {
  const { authBoundary } = useContext(AuthBoundaryContext);
  const { isCommercial = true } = useBuildContext();
  const [accessToken, setAccessToken] = useState('');

  React.useEffect(() => {
    const getAccessToken = async () => {
      const token = await authBoundary.getAccessToken();
      setAccessToken(token);
    };

    getAccessToken();
  }, []);

  if (!isCommercial) {
    return accessToken && <NonCommercialHigHeader />;
  }

  // Control should not reach here as `isCommercial` should never be true.
  // Reason being, we migrated all apps on feynman to AEM + CFP and feynman is
  // only used to render non-commercial apps.
  return null;
};

export default HigHeader;
