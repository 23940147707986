import React, { useState } from 'react';
import { withAxios } from '@account-portal/react-axios';
import cls from 'classnames';
import Avatar from '@mui/material/Avatar';
import styles from './MeMenu.less';
import Typography from '@digital-hig/typography';
import ThemeMui from '@digital-hig/theme-mui';

import { ClientAuthHandler } from '../../../../ClientAuthHandler';
import { localtranslations } from '../localtranslations';

const MeMenu = ({ axios }) => {
  const [expanded, setExpanded] = useState(false);

  const signOut = () => {
    const authHandler = new ClientAuthHandler(axios, false);
    authHandler.signout();
  };

  const renderMeMenu = () => (
    <ThemeMui themePrefix="fedramp-me-menu">
      <div className={styles.meMenuComponentContainer}>
        <div>
          <button
            onClick={() => setExpanded(!expanded)}
            className={styles.meMenuButtonUser}
            aria-expanded={expanded}
          >
            <Avatar
              sx={{
                height: '1.5em',
                width: '1.5em',
              }}
              src={process.env.ADSK_AVATAR_IMG_URL}
            />
          </button>
          <div
            className={cls(styles.meMenuPopover, expanded && styles.visible)}
          >
            <div className={styles.meMenuPopoverContainer}>
              <div className={styles.meMenuPopoverUser}>
                <button className={styles.meMenuSignOut} onClick={signOut}>
                  <Typography variant="headline-smallest">
                    {localtranslations({ key: 'SIGN_OUT' })}
                  </Typography>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThemeMui>
  );

  return renderMeMenu();
};

const MeMenuWithAxios = withAxios(MeMenu);

export default MeMenuWithAxios;
