import React, { useContext, useEffect, useState } from 'react';
import {
  Axios,
  createAxiosInstance,
  apis,
  withAxios,
} from '@account-portal/react-axios';

import {
  useBuildContext,
  AuthBoundaryContext,
} from '@account-portal/feynman-core';

import AuthBoundaryProvider from '../AuthBoundaryProvider/AuthBoundaryProvider';
import BuildContextProvider from '../BuildContextProvider/BuildContextProvider';
import { fetchEssentials } from '../../helpers/essentials';

const apisToUse = (env = process.env.NODE_ENV) => apis[env];

const wrapperAuthInstance = createAxiosInstance(
  apisToUse(),
  {
    withCache: false,
    withRetry: true,
    maxRetry: 2,
    retryTimeout: 4000,
    retryBackoff: 3,
  },
  null,
  process.env.NODE_ENV
);

const wrapperInstance = authBoundary =>
  createAxiosInstance(
    apis[process.env.NODE_ENV],
    {
      withCache: true,
      withRetry: true,
    },
    authBoundary,
    process.env.NODE_ENV
  );

const Essentials = ({ axios, children }) => {
  const { isCommercial = true } = useBuildContext();
  const [loading, setLoading] = useState(true);

  /* istanbul ignore next */
  useEffect(() => {
    const callEssentials = async () => {
      await fetchEssentials(axios, isCommercial);
      setLoading(false);
    };
    if (loading) {
      callEssentials();
    }
  }, [loading]);
  return loading ? <React.Fragment /> : children;
};

export const EssentialsWithAxios = withAxios(Essentials);

const ContextConsumer = ({ children }) => {
  const { authBoundary } = useContext(AuthBoundaryContext);
  return (
    <Axios.Provider
      value={{
        axios: wrapperInstance(authBoundary),
        env: process.env.NODE_ENV,
      }}
    >
      <EssentialsWithAxios>{children}</EssentialsWithAxios>
    </Axios.Provider>
  );
};

const ContextProviders = ({ children }) => (
  <BuildContextProvider>
    <Axios.Provider
      value={{
        axios: wrapperAuthInstance,
        env: process.env.NODE_ENV,
      }}
    >
      <AuthBoundaryProvider>
        <ContextConsumer>{children}</ContextConsumer>
      </AuthBoundaryProvider>
    </Axios.Provider>
  </BuildContextProvider>
);

export default ContextProviders;
