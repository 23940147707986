const getCorrectedEnv = env => {
  const envMap = {
    local: 'stg',
    dev: 'dev',
    stg: 'stg',
    int: 'int',
  };

  return envMap[env] || 'prd';
};

export const prepareDestination = () => {
  const env = window.location.hostname.split(/-/)[0];

  window?.cfp?.providers?.environment?.init(getCorrectedEnv(env));

  if (process.env.NODE_ENV !== 'production') {
    window.sessionStorage.setItem('ACCT_LOG_LEVEL', 'DEBUG');
  }
};
