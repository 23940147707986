const getRoutesToScripts = route => {
  if (!route) return null;

  const routesToScriptsList = [
    {
      route: '/user-access',
      scriptPath: 'ADSK_USER_ACCESS_JS_PATH',
      importPath: 'ADSK_ACCOUNT_PORTAL_APP_USER_ACCESS',
    },
  ];

  return routesToScriptsList.find(item => item.route === route) || {};
};

export default getRoutesToScripts;
