import React from 'react';
import { I18nProvider } from '@account-portal/ui-react-i18n';
import Logger from '../../helpers/logger';

export const I18nWrapper = ({ render: wrapperRenderer }) => {
  const languageCode = 'en';

  Logger.info('I18n: Non Commercial Mode defaults to en-US');

  return (
    <I18nProvider
      locale={languageCode}
      render={({ locale, loading }) => wrapperRenderer({ locale, loading })}
    />
  );
};
