import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import ContextProviders from './context/ContextProviders/ContextProviders';
import { I18nWrapper } from './components/I18nWrapper/I18nWrapper';
import HigShell from './components/Shell/HigShell';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import IFrameAlert from './components/IFrameAlert/IFrameAlert';
import AppSwitch from './components/AppSwitch/AppSwitch';
import { prepareDestination } from './helpers/prepareDestination';

const App = () => {
  prepareDestination();
  return (
    <ContextProviders>
      <I18nWrapper
        render={({ loading }) => (
          <span>
            <IFrameAlert>
              <BrowserRouter>
                <React.Fragment>
                  <ScrollToTop />
                  <HigShell loading={loading}>
                    <AppSwitch />
                  </HigShell>
                </React.Fragment>
              </BrowserRouter>
            </IFrameAlert>
          </span>
        )}
      />
    </ContextProviders>
  );
};

export default App;
