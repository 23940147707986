exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".pageHeaderWrapper--2PK9j{position:relative}", ""]);

// exports
exports.locals = {
	"pageHeaderWrapper": "pageHeaderWrapper--2PK9j"
};