import React from 'react';
import cls from 'classnames';
import { UniversalHelp } from '@account-portal/feynman-core';
import FedrampHeader from '../../Header/FedHeader/FedrampHeader';
import { pageHeaderWrapper } from './HigHeader.less';

const NonCommercialHigHeader = () => (
  <div className={cls(pageHeaderWrapper, 'adsk-account-page-header')}>
    <FedrampHeader />
    <UniversalHelp languageCode="en" countryCode="us" />
  </div>
);

export default NonCommercialHigHeader;
