/* istanbul ignore file */
import React from 'react';
import { withAxios } from '@account-portal/react-axios';
import {
  BuildContext,
  AuthBoundary,
  AuthBoundaryContext,
} from '@account-portal/feynman-core';

import {
  getAccessTokenFromPkceClient,
  getAccessToken,
  initializeSession,
  isUserAuthenticated,
  hasCepSession,
} from '../../helpers/authHelpers';
import { ClientAuthHandler } from '../../ClientAuthHandler';
import {
  parseAndSaveReturnUrl,
  redirectToReturnUrlIfAvailable,
} from '../../helpers/returnUrl';
import logger from '../../helpers/logger';

class AuthBoundaryProvider extends React.Component {
  static contextType = BuildContext;

  constructor(props) {
    super(props);

    this.clientAuthHandler = null;
    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const { axios } = this.props;
    const { isCommercial = true } = this.context;
    this.clientAuthHandler = new ClientAuthHandler(axios, isCommercial);
    if (isCommercial) {
      await (async () => {
        let initializeSessionResponse;

        const url = new URL(window.location.href);
        const returnUrl = url.searchParams.get('returnUrl');
        const path = `${returnUrl}${url.hash}`;
        logger.info('CHASSIS - path', path);

        try {
          // Redirect P & S CEP pages to AEM pages
          switch (true) {
            case path === '/cep/':
              window.location.href = `${url.origin}/products`;
              break;
            case path.match(/^\/cep\/#products-services\/updates.*$/) !== null:
              window.location.href = `${url.origin}/products/updates`;
              break;
            case path.match(
              /^\/cep\/#products-services\/products\/generate-license.*$/
            ) !== null:
              window.location.href = `${url.origin}/products/generate-license`;
              break;
            case path.match(/^\/cep\/#products-services.*$/) !== null:
              window.location.href = `${url.origin}/products`;
              break;
          }

          logger.info(
            'CHASSIS - await isUserAuthenticated()',
            await isUserAuthenticated()
          );
          logger.info('CHASSIS - hasCepSession()', hasCepSession());

          if (!((await isUserAuthenticated()) && hasCepSession())) {
            logger.info(
              'Needs to be authenticated. Saving return url if needed.'
            );
            await parseAndSaveReturnUrl();
          }
          const pkceResponse = await getAccessTokenFromPkceClient();
          // Some of the existing components still are referring to old non PKCE auth
          // So, getAccessTokenFromPkceClient could return null during unit tests ONLY
          const { token } = pkceResponse;
          if (token) {
            this.clientAuthHandler.setAccessToken(token);
            logger.info('Injecting a new session into cep using the token');
            logger.debug('Token:', token);
            initializeSessionResponse = await initializeSession(axios, token);
            if (
              initializeSessionResponse.result &&
              initializeSessionResponse.result ===
                'Failed to initialize session'
            ) {
              const { summary, details } = initializeSessionResponse;
              if (summary.toLowerCase().includes('emulation')) {
                alert(
                  `Failed to initialize for the following reason:\n${summary}\n${details}`
                );
              }
              window.location.reload();
            }
          }
        } catch (er) {
          logger.info(
            `Failed to initialize application: ${JSON.stringify(er)}`
          );
        }
        /* istanbul ignore if */
        if (
          (await isUserAuthenticated()) &&
          !redirectToReturnUrlIfAvailable(initializeSessionResponse) &&
          initializeSessionResponse &&
          initializeSessionResponse.sessionObj
        ) {
          window.cfp.providers.data.set(
            'metadata/user/session',
            initializeSessionResponse.sessionObj
          );
          this.setState({ loading: false });
        }
      })();
    } else {
      await (async () => {
        const token = isCommercial
          ? await getAccessToken(axios)
          : (await getAccessTokenFromPkceClient()).token;
        this.clientAuthHandler.setAccessToken(token);
        this.setState({ loading: false });
      })();
    }
  }

  render() {
    const { children } = this.props;
    const { loading } = this.state;

    return (
      !loading && (
        <AuthBoundaryContext.Provider
          value={{
            authBoundary: AuthBoundary.createAuthBoundary(
              this.clientAuthHandler
            ),
          }}
        >
          {children}
        </AuthBoundaryContext.Provider>
      )
    );
  }
}

const AuthBoundaryProviderWithAxios = withAxios(AuthBoundaryProvider);

export default AuthBoundaryProviderWithAxios;
