import React, { useContext, useEffect } from 'react';
import {
  useBuildContext,
  AuthBoundaryContext,
} from '@account-portal/feynman-core';

import NonCommercialApp from './NonCommercialApp';
import logger from '../../helpers/logger';
import IdleTimer from '../../helpers/idleTimer';
import HomeRedirect from './HomeRedirect';

const AppSwitch = () => {
  const { isCommercial = true } = useBuildContext();

  const { authBoundary } = useContext(AuthBoundaryContext);

  const refreshIntervalTime = parseInt(
    process.env.ADSK_REFRESH_INTERVAL_TIME,
    10
  );

  useEffect(() => {
    const it = new IdleTimer({
      onTimeout: async () => {
        await authBoundary.signout();
      },
      getAccessToken: async () => {
        logger.info(
          'AppSwitch: refreshIntervalTimer hit. Checking for access token'
        );
        await authBoundary.getAccessToken();
        logger.info(
          'AppSwitch: refreshIntervalTimer hit. Finished checking for access token'
        );
      },
      timeout: parseInt(process.env.ADSK_TIMEOUT, 10),
      inactiveIntervalTime: parseInt(
        process.env.ADSK_INACTIVE_INTERVAL_TIME,
        10
      ),
      refreshIntervalTime,
      userEventLagTime: parseInt(process.env.ADSK_USER_EVENT_LAG_TIME, 10),
      isCommercial,
    });
    return () => {
      it.cleanUp();
    };
  }, []);

  if (!isCommercial) {
    return <NonCommercialApp />;
  }

  return <HomeRedirect />;
};

export default AppSwitch;
