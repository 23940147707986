import getPkceAuthClient from '../pkceclient';
import logger from './logger';
// import { shouldSkipCache } from './returnUrl';

export const hasCepSession = () => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${process.env.ADSK_CEP_SESSION_TOKEN}=`);
  return parts.length === 2;
};
export const isUserAuthenticated = async () => {
  const client = await getPkceAuthClient();
  return client.isAuthenticated();
};

export const getAccessTokenFromPkceClient = async () => {
  let token = null;
  let client;
  try {
    client = await getPkceAuthClient();
    token = await client.getTokenSilently();
  } catch (error) {
    logger.info('ERROR: ', error);
    /* istanbul ignore else */
    if (client) {
      logger.error(error, 'AUTH_HELPER: Redirecting to login page...');
      await client.loginWithRedirect();
    }
  }
  return { token };
};

export const initializeSession = async (axios, token) => {
  const apiDomain = process.env.ADSK_FYN_API_DOMAIN;
  logger.info(
    process.env.NODE_ENV,
    'Updating cep session with new refreshed token...'
  );
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  // const skipCache = shouldSkipCache() ? '&skipCache=true' : '';
  return (
    axios
      // .post(`gatekeeper/v1/initialize?oidc=true${skipCache}`, '', { headers })
      .post(`${apiDomain}/gatekeeper/v1/initialize`, '', { headers })
      .then(({ data }) => {
        logger.info('Successfully injected the session.');
        return data;
      })
  );
};

export const terminateSession = async axios =>
  axios.get(`${process.env.ADSK_FYN_API_DOMAIN}/gatekeeper/v1/terminate`);

export const getAccessToken = async axios => {
  const response = await axios.get(
    `${process.env.ADSK_FYN_API_DOMAIN}/service/authentication/access-token`
  );
  return response.data.accessToken;
};

export const refreshAccessToken = async axios => {
  try {
    const response = await axios.post(
      `${process.env.ADSK_FYN_API_DOMAIN}/service/authentication/refresh-token`
    );
    return response.data.accessToken;
  } catch (err) {
    logger.error(err, 'Failed to refresh access token ');
    return '';
  }
};

export const logout = async (returnUrl = '') => {
  try {
    const client = await getPkceAuthClient();
    const logoutOption = { logoutRedirectUri: returnUrl };
    await client.logout(logoutOption);
  } catch (error) {
    logger.error(error, 'Failed to logout ');
  }
};
