import React, { useEffect, useState } from 'react';

import getRoutesToScripts from './helpers/getRoutesToScripts';
import appendScript from './helpers/appendScript';
import loadApp from './helpers/loadApp';

let App;

const RouteLoader = props => {
  const { route } = props;
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const { importPath } = getRoutesToScripts(route);

  useEffect(async () => {
    if (window[importPath] && !scriptLoaded) {
      App = await loadApp(props);
      setScriptLoaded(true);
    } else {
      const script = await appendScript(route);
      script.onload = async () => {
        App = await loadApp(props);
        setScriptLoaded(true);
      };
    }
  }, []);

  return scriptLoaded && <App.Component {...props} />;
};

export default RouteLoader;
