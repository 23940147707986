import logger from '../helpers/logger';

export const setData = (key, value) => {
  window.cfp.providers.data.set(key, { ...value, t: Date.now(), v: value });
};

export const getData = key => window.cfp.providers.data.get(key);

export const subscribeForData = (key, callback) => {
  window.cfp.providers.data.subscribe(key, callback);
};

export const unsubscribe = callback => {
  logger.debug(`Unsubscribing from data provider for `, callback);
  window.cfp.providers.data.unsubscribe(callback);
};

export const subscribeForAllData = (keys, callback, allKeysNeeded = true) => {
  const cb = async () => {
    const alldata = {};
    let lastUpdatedOn = 0;

    const dataLookups = [];
    keys.forEach(key => {
      dataLookups.push(
        window.cfp.providers.data.get(key).then(data => ({ key, data }))
      );
    });
    const lookupResults = await Promise.allSettled(dataLookups);
    let missingData = false;
    lookupResults.forEach(rslt => {
      if (rslt.status === 'fulfilled' && rslt.value.data) {
        const { key, data } = rslt.value;
        const { t, v } = data;
        if (t && v) {
          alldata[key] = v;
          if (t > lastUpdatedOn) {
            lastUpdatedOn = t;
          }
        } else {
          missingData = true;
        }
      } else {
        missingData = true;
      }
    });

    if (missingData && allKeysNeeded) {
      return;
    }

    try {
      callback(alldata, lastUpdatedOn);
    } catch (er) {
      /* istanbul ignore next */
      logger.info('Exception during callback', er);
    }
  };
  keys.forEach(key => subscribeForData(key, cb));
  return cb;
};
