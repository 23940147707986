/* istanbul ignore file */
import React from 'react';
import ThemeMui from '@digital-hig/theme-mui';
import Typography from '@digital-hig/typography';

import { localtranslations } from '../localtranslations';
import styles from './Title.less';

const Logo = () => (
  <ThemeMui
    overrides={{
      MuiTypography: {
        root: {
          color: '#fff',
          fontSize: '1em',
        },
      },
    }}
    themePrefix="light"
  >
    <div className={styles.titleContainer}>
      <Typography>
        {localtranslations({
          key: 'ACCOUNT_FOR_GOVERNMENT',
        })}
      </Typography>
    </div>
  </ThemeMui>
);

export default Logo;
