/* istanbul ignore file */
import logger from './logger';
import { setData } from '../cfp/CFPUtils';
import { DataKeys } from '../MFEDataContract';

const extractEssentialFieldOnly = (loggedInUser, emulateeUser) => {
  let lu;
  let eu;
  const fieldsToSelect = [
    'firstName',
    'lastName',
    'email',
    'languageCode',
    'countryCode',
    'oxygenId',
    'contactCsns',
    'guid',
    'analyticsId',
    'accountEmulationPciCertified',
  ];
  [loggedInUser, emulateeUser].forEach((profileObj, index) => {
    const obj = {};
    fieldsToSelect.forEach(f => {
      if (profileObj[f] !== undefined) {
        obj[f] = profileObj[f];
      }
    });
    const avatar =
      profileObj.profileImages && profileObj.profileImages.length
        ? profileObj.profileImages[0].url
        : undefined;
    const additionalFields = {
      name: `${obj.firstName} ${obj.lastName}`,
      avatar,
    };
    if (index === 0) {
      lu = Object.assign({}, obj, additionalFields);
    } else {
      eu = Object.assign({}, obj, additionalFields);
    }
  });
  return [lu, eu];
};

const extractUserProfile = async (sessionObj, isCommercial = true) => {
  if (!isCommercial) {
    // set the non commercial user profile if isCommercial is false
    const nonCommercialUserProfile = {
      loggedInUser: {
        languageCode: 'en',
        countryCode: 'US',
      },
    };

    setData(DataKeys.USER_PROFILE, nonCommercialUserProfile);
    return;
  }

  let { loggedInUser, emulateeUser = {} } = sessionObj;
  logger.debug('extractUserProfile: user objects', loggedInUser, emulateeUser);
  [loggedInUser, emulateeUser] = extractEssentialFieldOnly(
    loggedInUser,
    emulateeUser
  );

  logger.debug(
    'extractUserProfile: user objects after filtering',
    loggedInUser,
    emulateeUser
  );

  const inEmulation = !!sessionObj.emulateeUser;
  const userProfile = Object.assign(
    {},
    {
      loggedInUser,
      inEmulation,
      dataStatus: 'ready',
    },
    { ...(inEmulation ? emulateeUser : loggedInUser) }
  );

  setData(DataKeys.USER_PROFILE, userProfile);
};

const getPrivileges = async axios => {
  logger.debug('Fetching privileges');
  let privilegesResponse = {};
  try {
    privilegesResponse = await axios.get(
      `${process.env.ADSK_FYN_API_DOMAIN}/service/user/v2/privileges?includeSubscriberContextIds=true`
    );
    privilegesResponse = privilegesResponse.data;
  } catch (er) {
    logger.info(
      'Failed to fetch privileges to set into CFP Data Provider. Some functionality may be impacted.',
      er
    );
  }
  const { privileges = [], subscriberContextIds = [] } = privilegesResponse;
  window.cfp.providers.data.set(DataKeys.USER_PRIVILEGES, {
    privileges,
    t: Date.now(),
    v: privileges,
  });
  window.cfp.providers.data.set(DataKeys.USER_SUBS_CONTEXTIDS, {
    subscriberContextIds,
    t: Date.now(),
    v: subscriberContextIds,
  });
};

const getFlags = async axios => {
  logger.debug('Fetching Flags');
  let flagsResponse = {};
  try {
    flagsResponse = await axios.get(
      `${process.env.ADSK_FYN_API_DOMAIN}/feature-management/v1/flags`
    );
    flagsResponse = flagsResponse.data;
  } catch (er) {
    logger.info(
      'Failed to fetch flags to set into CFP Data Provider. Some functionality may be impacted.',
      er
    );
  }
  setData(DataKeys.USER_FLAGS, flagsResponse);
};

export const fetchEssentials = async (axios, isCommercial) => {
  const sessionObj = await window.cfp.providers.data.get(
    'metadata/user/session'
  );

  logger.debug('Extracting User Profile for non-commercial application.');
  if (!isCommercial)
    return Promise.allSettled([extractUserProfile(sessionObj, isCommercial)]);

  logger.debug('Fetching essentials and extracting User Profile.');
  return Promise.allSettled([
    getPrivileges(axios),
    getFlags(axios),
    extractUserProfile(sessionObj, isCommercial),
  ]);
};
