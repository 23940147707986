import getRoutesToScripts from './getRoutesToScripts';

const appendScript = route => {
  if (!route) return null;

  const {
    scriptPath,
    importPath,
    cfp = false,
    domainOverride = null,
  } = getRoutesToScripts(route);
  let script = document.getElementById(importPath);

  if (!script && scriptPath && importPath) {
    const scriptDomain =
      domainOverride ||
      (cfp ? process.env.ADSK_CFP_PUBLIC_URL : process.env.PUBLIC_URL);
    script = document.createElement('script');
    script.src = `${scriptDomain}${process.env[scriptPath]}`;
    script.type = 'text/javascript';
    script.async = false;
    script.id = importPath;

    const titleTag = document.head.getElementsByTagName('title')[0];

    if (titleTag)
      titleTag.parentNode.insertBefore(script, titleTag.nextSibling);
  }

  return script;
};

export default appendScript;
